import React, {useEffect, useState,} from "react";
import HomeScreen from "./screens/HomeScreen";
import ApplicationScreen from "./screens/ApplicationScreen";
import TrackApplicationScreen from "./screens/TrackApplicationScreen";
import AdminLoginScreen from "./screens/AdminLoginScreen";
import AdminDashboardScreen from "./screens/AdminDashboardScreen";
import AdminForgotPasswordScreen from "./screens/AdminForgotPasswordScreen";
import AdminChangePasswordScreen from "./screens/AdminChangePasswordScreen";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import ModelListingScreen from "./screens/ModelListingScreen";
import FaqScreen from "./screens/FaqScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import PrintApplicationSummary2 from "./screens/PrintApplicationSummary2";
import PageNotFound from "./screens/PageNotFound";
import LoadingScreen from "./screens/LoadingScreen";

import { BrowserRouter as Router, Route } from "react-router-dom";

import UserForm from "./components/admin_forms/UserForm";
import AdminScreen from "./screens/AdminScreen";

const allowedIP = [
  '206.189.156.244', //dev IP
  '203.95.14.157',
  '202.128.10.10',

  '136.158.29.48',
  '124.106.181.196',
  '120.29.68.32',
  '180.191.57.98',
  '120.29.68.173',
  '112.211.14.188',
  '136.158.41.94',  //dev IP
  '136.158.41.184',  //dev IP
  '175.176.21.253',  //dev IP
  '136.158.40.188',  //dev IP
  '112.208.176.100',// Jonas IP
  '158.62.35.63',//Riza IP
  '161.49.90.23', // dev IP
  '103.66.143.238', // dev IP
  '206.189.156.244', // Charles IP
]

function App() {
  const [isAllowed, setIsAllowed] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    async function fetchIP(){
      await delay(1000);
      fetch('https://api.ipify.org', {
      method: 'GET',
      header: {},
      })
      .then(res => {
        return res.text()
      }).then(ip => {
        if(allowedIP.includes(ip)){
          setIsAllowed(true)
        }
        setIsLoading(false)
      });
       console.log(isAllowed);
    }

    fetchIP();
    
  }, [])
  
  if(window.location.host.startsWith("www")){
    console.log("www detected")
    const cleaned_host = window.location.host.replace('www.','')
    console.log(window.location.protocol + "//" + cleaned_host + window.location.pathname)
    window.location = window.location.protocol + "//" + cleaned_host + window.location.pathname
  }

  return (
    <Router>
      {isAllowed == true ?
        (<>
          <Route path="/admin" component={isLoading == false? AdminLoginScreen: LoadingScreen} exact />
          <Route path="/dashboard" component={isLoading == false? AdminScreen: LoadingScreen} />
        </>):
        <>
          <Route path="/admin" component={isLoading == false ? HomeScreen: LoadingScreen} exact />
          <Route path="/dashboard" component={isLoading == false ? HomeScreen: LoadingScreen} />
        </>
      }

      <Route path="/loading" component={LoadingScreen} exact/>
      <Route path="/" component={HomeScreen} exact />
      <Route path="/application" component={ApplicationScreen} exact />
      <Route path="/track" component={TrackApplicationScreen} exact />
      <Route path="/track/:ctrl_no" component={TrackApplicationScreen} exact />
      
      <Route path="/forgot" component={AdminForgotPasswordScreen} exact />
      <Route path="/change" component={AdminChangePasswordScreen} exact />
      <Route path="/email-change/:creds" component={ChangePasswordScreen} />
      <Route path="/changepass" component={ResetPasswordScreen} />
      <Route
        path="/printapplication"
        component={PrintApplicationSummary2}
        exact
      />
      <Route path="/model-listing" component={ModelListingScreen} exact />
      <Route path="/faq" component={FaqScreen} exact />
      <Route path="/register" component={UserForm} />
      {/* <Footer /> */}
      
    </Router>
  );
}

export default App;
